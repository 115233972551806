import {
    useAppSelector,
    selectPossibleBulkCrosslistingListings,
    selectAreListingDefaultsSetForDomains,
    selectListingDefaultsConfig,
    selectBulkCrosslistingSessionId,
    useAppDispatch,
    Logger,
    setBulkCrosslistingConfig,
    submitBulkCrosslistingSessionDraft,
    updateSelectedBulkCrosslistingIds,
    getBulkCrosslistingSessionPopulated,
    smartSearch,
    getAllListingInfos,
    cancelBulkCrosslistingSession
} from '@foxtail-dev/user-clients'
import { Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { generateToast } from '../../lib/clients/ToastClient'
import { ListingInfo } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingInfo'
import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../common/FlexGrow'
import { FoxButton } from '../common/FoxButton'
import { FoxCircleLoader } from '../LoadingCircle'
import { FoxSearchBar } from '../common/FoxSearchBar'
import { BulkCrosslistDraftRow } from './BulkCrosslistDraftRow'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import React from 'react'

export type ListingSelectedMap = Record<string, boolean>

type BulkCrosslistDraftProps = {
    setRequestCancel: React.Dispatch<React.SetStateAction<boolean>>
}

export const BulkCrosslistDraft = (props: BulkCrosslistDraftProps) => {
    const { setRequestCancel } = props
    const dispatch = useAppDispatch()

    const possibleListingInfos = useAppSelector(selectPossibleBulkCrosslistingListings)
    const areListingDefaultsSet = useAppSelector(selectAreListingDefaultsSetForDomains)
    const listingDefaults = useAppSelector(selectListingDefaultsConfig)
    const bulkCrosslistingSessionId = useAppSelector(selectBulkCrosslistingSessionId)
    const hasListings = possibleListingInfos.length > 0

    const [search, setSearch] = useState<string>('')
    const [loading, setLoading] = useState(true)

    const handleSubmit = async (values: ListingSelectedMap, actions: FormikHelpers<ListingSelectedMap>) => {
        if (!areListingDefaultsSet) {
            actions.setStatus('Please set listing defaults before crosslisting')

            return
        }

        const selectedListingIds = Object.keys(values).filter((listingId) => values[listingId])
        if (selectedListingIds.length === 0) {
            actions.setStatus('Please choose at least one listing to crosslist')
            return
        }

        try {
            await dispatch(setBulkCrosslistingConfig(listingDefaults)).unwrap()
            await dispatch(updateSelectedBulkCrosslistingIds(selectedListingIds)).unwrap()
            await dispatch(submitBulkCrosslistingSessionDraft()).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User submitted bulk crosslisting session draft',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        bulkCrosslistingSessionId
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error submitting bulk crosslisting session draft',
                    payload: {
                        kind: 'BulkCrosslistDraftError',
                        entry: {
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error submitting listings for generation', subText: 'Please try again' })
        }
    }

    const onCancel = async () => {
        try {
            await dispatch(cancelBulkCrosslistingSession()).unwrap()
            setRequestCancel(true)

            Logger.I().log({
                level: 'info',
                message: 'User cancelled bulk crosslisting session',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        bulkCrosslistingSessionId
                    }
                }
            })
        } catch (error) {
            setRequestCancel(false)
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error cancelling bulk crosslisting session',
                    payload: {
                        kind: 'cancelBulkCrosslistingSessionError',
                        entry: {
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
        }
    }

    const filteredListingIds = useMemo(() => {
        if (search === '') {
            return possibleListingInfos.map((li) => li._id)
        }

        const key = 'title'
        const results = smartSearch<ListingInfo>({
            input: search,
            list: possibleListingInfos,
            keys: [key]
        })

        return results
            .sort((a, b) => {
                return a.item._id.localeCompare(b.item._id)
            })
            .map((result) => result.item._id)
    }, [search, possibleListingInfos])

    const getListingInfosAsync = async () => {
        try {
            await Promise.all([dispatch(getBulkCrosslistingSessionPopulated()).unwrap(), dispatch(getAllListingInfos()).unwrap()])
            setLoading(false)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error fetching listing infos',
                    payload: {
                        kind: 'fetchListingInfosError',
                        entry: {
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error fetching listings', subText: 'Please try again' })
        }
    }

    useEffect(() => {
        getListingInfosAsync()
    }, [])

    const initialValues: ListingSelectedMap = {}
    filteredListingIds.forEach((listingId) => {
        initialValues[listingId] = false
    })

    const cancelBulkCrosslist = async () => {}

    return loading ? (
        <Box sx={styles.containerLoading}>
            <FlexGrow />

            <Box sx={styles.containerCircleLoader}>
                <FoxCircleLoader />
            </Box>
            <FlexGrow />
            <FoxButton
                primary
                sx={styles.buttonLoading}
                text='Cancel bulk crosslist'
                onFoxClick={{
                    kind: 'button',
                    onClick: cancelBulkCrosslist,
                    preventDoubleClick: true
                }}
            />
        </Box>
    ) : (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur={false} enableReinitialize={false} validateOnChange={false}>
            {({ handleSubmit, setValues, isSubmitting, values }: FormikProps<ListingSelectedMap>) => {
                // renderItem for virtualizedLists must be within a useCallback to avoid it getting re-created
                const renderItem = useCallback(
                    ({ index, style }: ListChildComponentProps<string>) => {
                        const { values } = useFormikContext<ListingSelectedMap>()

                        const listingId = filteredListingIds[index] ?? ''

                        // This <div> must be here, the style element is required to prevent flickering
                        return (
                            <div style={style}>
                                <BulkCrosslistDraftRow key={listingId} listingId={listingId} isChecked={values[listingId] ?? false} />
                            </div>
                        )
                    },
                    [filteredListingIds]
                )

                const areAllSelected = filteredListingIds.every((listingId) => values[listingId])

                const onSelectAll = useCallback(() => {
                    const newValues = { ...values }
                    filteredListingIds.forEach((listingId) => {
                        newValues[listingId] = true
                    })
                    setValues(newValues)
                }, [values, filteredListingIds, setValues])

                const onDeselectAll = useCallback(() => {
                    const newValues = { ...values }
                    filteredListingIds.forEach((listingId) => {
                        newValues[listingId] = false
                    })
                    setValues(newValues)
                }, [values, filteredListingIds, setValues])

                const selectedListingCount = Object.keys(values).filter((listingId) => values[listingId]).length

                return (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '600px', overflowY: 'auto' }}>
                            <FoxSearchBar label='Search listings' value={search} onChange={setSearch} sx={styles.searchBar} />
                            <Box sx={styles.containerImportItems}>
                                <FixedSizeList height={550} itemCount={filteredListingIds.length} itemSize={96} width='100%'>
                                    {renderItem}
                                </FixedSizeList>
                            </Box>
                        </Box>

                        <Divider />
                        <Box sx={styles.containerButtons}>
                            {hasListings && areAllSelected ? (
                                <FoxButton grey onFoxClick={{ kind: 'button', onClick: onDeselectAll }} text='Deselect all' style={styles.button} />
                            ) : (
                                <FoxButton grey onFoxClick={{ kind: 'button', onClick: onSelectAll }} text='Select all' style={styles.button} />
                            )}
                            <FoxButton
                                sx={{ marginLeft: '14px' }}
                                grey
                                onFoxClick={{ kind: 'button', onClick: onCancel, preventDoubleClick: true }}
                                text='Cancel'
                                style={styles.button}
                            />
                            <FoxButton
                                sx={{ marginLeft: '14px' }}
                                primary
                                loading={isSubmitting}
                                onFoxClick={{ kind: 'button', onClick: async () => await handleSubmit(), preventDoubleClick: true }}
                                text={`Continue (${selectedListingCount})`}
                                style={styles.button}
                            />
                        </Box>
                    </>
                )
            }}
        </Formik>
    )
}

const styles = {
    containerLoading: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerButtons: {
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        paddingBottom: '12px'
    },
    containerCircleLoader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    textLoading: {
        textAlign: 'center',
        marginLeft: '80px',
        marginRight: '80px',
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        marginTop: '30px'
    },
    buttonLoading: {
        width: '100%',
        justifyContent: 'center'
    },
    searchBar: {
        width: '100%',
        marginBottom: '40px',
        padding: '20px'
    },
    containerImportItems: {
        marginLeft: '30px'
    },
    button: {
        width: '120px',
        justifyContent: 'center'
    }
}
