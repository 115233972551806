import { useCallback, useRef, useState } from 'react'
import {
    Logger,
    selectSentNotificationInChronologicalOrder,
    selectTimeRange,
    setAllUnreadNotificationsToRead,
    setNotificationAsRead,
    TimeRange,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { NotificationRow } from '../../containers/home/NotificationRow'
import { NotificationsZeroState } from '../../containers/home/NotificationsZeroState'
import { FoxButton } from '../../components/common/FoxButton'
import { FlexGrow } from '../../components/common/FlexGrow'
import { SpecificNotificationModal } from '../../modals/home/SpecificNotificationModal'
import { NotificationSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/notifications/Notification'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

export const NotificationsScreen = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(selectSentNotificationInChronologicalOrder)
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState<boolean>(false)
    const [notificationSelected, setNotificationSelected] = useState<NotificationSchema | null>(null)

    const currentTimeRangeRef = useRef<TimeRange>('week')
    currentTimeRangeRef.current = useAppSelector(selectTimeRange)

    const handleMarkAllAsRead = async () => {
        try {
            if (isMarkingAllAsRead) return
            setIsMarkingAllAsRead(true)
            await dispatch(setAllUnreadNotificationsToRead()).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User clicked on mark all read',
                payload: {
                    kind: 'UserAction'
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error marking all notifications as read',
                    payload: {
                        kind: 'MarkAllAsReadError'
                    }
                },
                error
            )
        }
        setIsMarkingAllAsRead(false)
    }

    const openNotificationModal = (notification: NotificationSchema) => {
        if (notification.status !== 'read') {
            dispatch(setNotificationAsRead(notification._id))
        }

        Logger.I().log({
            level: 'info',
            message: 'User clicked on notification',
            payload: {
                kind: 'UserAction',
                entry: {
                    notificationId: notification._id
                }
            }
        })

        setNotificationSelected(notification)
    }

    const renderNotificationRow = useCallback(
        ({ index, style }: ListChildComponentProps<string>) => {
            const notification = notifications[index]
            if (!notification) {
                return <></>
            }

            return (
                <div style={style}>
                    <NotificationRow key={'NotificationRow' + notification._id} notification={notification} openModal={openNotificationModal} />
                </div>
            )
        },
        [notifications]
    )

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerNotifications}>
                <Box sx={styles.containerRow}>
                    <FoxTypography variant='h5' sx={styles.textNotification}>
                        Notifications
                    </FoxTypography>
                    <FlexGrow />
                    <FoxButton text='Mark all as read' onFoxClick={{ kind: 'button', onClick: handleMarkAllAsRead, preventDoubleClick: true }} />
                </Box>
                {notifications.length === 0 ? (
                    <NotificationsZeroState />
                ) : (
                    <Box sx={{ height: '80vh', overflowY: 'hidden' }}>
                        <FixedSizeList height={2000} itemCount={notifications.length} itemSize={96} width='100%'>
                            {renderNotificationRow}
                        </FixedSizeList>
                    </Box>
                )}
            </Box>
            <SpecificNotificationModal
                closeModal={() => setNotificationSelected(null)}
                open={notificationSelected !== null}
                notification={notificationSelected}
            />
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        alignItems: 'center'
    },
    containerNotifications: {
        width: '560px'
    },
    containerRow: {
        flexDirection: 'row',
        display: 'flex'
    },
    textNotification: {
        marginTop: '15px',
        marginBottom: '20px'
    }
}
