import React from 'react'

import './assets/fonts/Ubuntu-Regular.ttf'
import './assets/fonts/Ubuntu-Medium.ttf'
import './assets/fonts/Ubuntu-Bold.ttf'
import './app.css'

import { reduxStoreFactory } from '@foxtail-dev/user-clients'
import { Auth0Provider } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import * as Intercom from '@intercom/messenger-js-sdk'
import ReactGA from 'react-ga4'
import { Buffer } from 'buffer'
import { PostHogProvider } from 'posthog-js/react'
import { PostHogConfig } from 'posthog-js'
import posthog from 'posthog-js'
import App from './App'
import { FoxtailWebConfig } from './lib/config/FoxtailWebConfig'
import { sentryReduxEnhancer } from './lib/redux/SentryReduxEnhancer'
import { foxTheme } from './theme/FoxTheme'

// Ensure Buffer is available globally
// vite-plugin-node-polyfills was not working for globally setting Buffer
if (typeof window !== 'undefined') {
    window.Buffer = Buffer
}

// TODO: See what Sentry settings we want. For now turned off all tracing, etc.
Sentry.init({
    dsn: FoxtailWebConfig.config.sentry.dsn,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        }),
        posthog.sentryIntegration({
            organization: FoxtailWebConfig.config.sentry.org,
            projectId: FoxtailWebConfig.config.sentry.project,
            prefix: 'https://foxtail-dev.sentry.io/'
        })
    ],
    // Session Replay
    replaysSessionSampleRate: FoxtailWebConfig.config.env === 'prod' ? 0.05 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    normalizeDepth: 10
})

Intercom.default({
    app_id: FoxtailWebConfig.config.intercomAppId
})

ReactGA.initialize(FoxtailWebConfig.config.googleAnalytics.measurementId)

// Initialize Firebase
// const firebase = new FoxtailFirebase()
// Initialize Firebase Cloud Messaging and get a reference to the service
// firebase.setMessagingListener()

const useDevTools = FoxtailWebConfig.config.env === 'dev' || FoxtailWebConfig.config.env === 'beta'

const store = reduxStoreFactory(useDevTools, [], [sentryReduxEnhancer])

const posthogOptions: Partial<PostHogConfig> = {
    api_host: FoxtailWebConfig.config.posthog.host,
    session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
            password: true // Only mask password inputs, nothing else
        }
    }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <PostHogProvider apiKey={FoxtailWebConfig.config.posthog.apiKey} options={posthogOptions}>
        <Auth0Provider
            domain={FoxtailWebConfig.config.auth0.domain}
            clientId={FoxtailWebConfig.config.auth0.clientId}
            cacheLocation='localstorage' // Use local storage to avoid third-party cookie issues
            authorizationParams={{
                redirect_uri: window.location.origin + '/callback',
                audience: FoxtailWebConfig.config.auth0.audience,
                scope: 'openid profile email offline_access'
            }}>
            <Provider store={store}>
                <ThemeProvider theme={foxTheme}>
                    <CssBaseline enableColorScheme />
                    <App />
                </ThemeProvider>
            </Provider>
        </Auth0Provider>
    </PostHogProvider>
)
