import { isValidPrice, normalizePrice } from '@foxtail-dev/user-clients'
import { FoxTextField } from './FoxTextField'
import { useState } from 'react'
import { BoxProps, TextFieldProps, TypographyVariant } from '@mui/material'

type PriceFieldProps = {
    name: string
    label?: string
    labelVariant?: TypographyVariant
    disabled?: boolean
    logOnBlur?: boolean
    sx?: BoxProps['sx']
    inputStyle?: TextFieldProps['sx']
    initialValue?: string
}

// TODO: Transform FoxTypography is being ignored
export const PriceField = ({ name, label = 'Price', disabled, logOnBlur, sx, inputStyle, initialValue, labelVariant = 'body1' }: PriceFieldProps) => {
    const [value, setValue] = useState<string | undefined>(initialValue)

    const transformText = (inputtedValue: string): string => {
        const previousValue = value || '0.00'
        const newValue = isValidPrice(inputtedValue) ? inputtedValue : previousValue
        setValue(newValue)
        return newValue
    }

    const onBlur = (value: string): string => {
        const newValue = normalizePrice(value)
        setValue(newValue)
        return newValue
    }

    return (
        <FoxTextField
            name={name}
            label={label}
            labelVariant={labelVariant}
            placeholder='0.00'
            inputStyle={{ ...inputStyle }}
            value={value}
            transformText={transformText}
            onBlur={onBlur}
            disabled={disabled}
            prefix={'$'}
            logOnBlur={logOnBlur}
            sx={{ ...sx }}
        />
    )
}
