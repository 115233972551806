import {
    useAppSelector,
    selectListingById,
    getSelectedMarkets,
    Colors,
    isActiveListingUpdating,
    ListingTabKind,
    copyListing,
    deleteActiveListing,
    deleteListingDraft,
    Logger,
    useAppDispatch,
    relistListing
} from '@foxtail-dev/user-clients'
import { useNavigate } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { BaseListingTableRow } from '../../components/listing/BaseListingTableRow'
import { EditIcon } from '../../components/icons/EditIcon'
import { ListingRowAction } from '../../components/listing/BaseListingRow'
import { generateToast } from '../../lib/clients/ToastClient'
import { CopyAsNewIcon } from '../../components/icons/CopyAsNewIcon'
import { DeleteIcon } from '../../components/icons/DeleteIcon'
import { DeleteDraftListingConfirmationModal } from '../../modals/listing/DeleteDraftListingConfirmationModal'
import { DeleteActiveListingConfirmationModal } from '../../modals/listing/DeleteActiveListingConfirmationModal'
import { getAllMarkets } from '../../utils/getAllMarkets'
import { Refresh } from '@mui/icons-material'
import { ListingRowComponentProps } from './ListingTable'

export const ListingTableRow = (props: ListingRowComponentProps) => {
    const { listingId, tab } = props

    const dispatch = useAppDispatch()
    const listing = useAppSelector(selectListingById(listingId))
    const navigate = useNavigate()

    const parsedTab = ListingTabKind.parse(tab)
    const listingDescription = listing.listingDescription
    const listingDetails = listingDescription.listingDetails
    const listingKind = listingDescription.kind
    const isUntracked = parsedTab === 'untracked'
    const selectedMarkets = isUntracked ? getAllMarkets(listingDetails) : getSelectedMarkets(listingDetails)
    const imageIds = listingDetails.imageIds
    const commonDetails = listingDetails.commonDetails
    const isDraftListing = listing.listingDescription.kind === 'draft'

    const onDetail = () => {
        if (isDraftListing) {
            navigate(`/app/create-listing/${listingId}`)
        } else {
            navigate(`/app/listings/${listingId}`)
        }
    }

    const isUpdating: boolean = useMemo(() => {
        if (listing.listingDescription.kind !== 'active') {
            return false
        }

        return isActiveListingUpdating(listing.listingDescription.kind, listing.listingDescription)
    }, [listing])

    const isListingDeleting = useMemo(() => {
        return listing.isDeleting || (listingDescription.kind === 'active' && listingDescription.listingDetails.userDeleteInitiated)
    }, [listing])

    const [isCopyingAsNew, setIsCopyingAsNew] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const isDraftListingDeleting = useMemo(() => {
        return listingDescription.kind === 'draft' && listingDescription.abandoned
    }, [listing])

    const onEdit = async () => {
        const url = isDraftListing ? `/app/create-listing/${listingId}` : `/app/edit-listing/${listingId}`
        navigate(url)
    }

    const onCopyAsNew = async (e: React.MouseEvent) => {
        e.stopPropagation()
        if (isCopyingAsNew || !listingId) return
        setIsCopyingAsNew(true)
        try {
            const newListingId = await dispatch(copyListing(listingId)).unwrap()
            navigate(`/app/create-listing/${newListingId}`)

            Logger.I().log({
                level: 'info',
                message: 'User copied listing as new',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        previousListingId: listingId,
                        newListingId
                    }
                }
            })
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Threw while trying to copy listing',
                    payload: {
                        kind: 'CopyAsNewError',
                        entry: {
                            previousListingId: listingId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Unable to copy as new' })
        }
        setIsCopyingAsNew(false)
    }
    const onDeleteInitiated = (e: React.MouseEvent) => {
        e.stopPropagation()
        setIsDeleteModalOpen(true)
    }

    const onDeleteModalClose = (result: boolean) => {
        setIsDeleteModalOpen(false)
        if (result) {
            deleteListing()
        }
    }

    const deleteListing = async () => {
        if (isDeleting || isListingDeleting || !listingId) {
            return
        }

        setIsDeleting(true)
        try {
            if (isDraftListing) {
                await dispatch(deleteListingDraft(listingId)).unwrap()
            } else {
                await dispatch(deleteActiveListing(listingId)).unwrap()
            }

            Logger.I().log({
                level: 'info',
                message: 'User deleted listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        isDraftListing
                    }
                }
            })
        } catch (error) {
            generateToast({ kind: 'info', message: 'Unable to delete listing', subText: 'Please try again later or contact support' })

            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to delete listing',
                    payload: {
                        kind: 'DeleteListingError',
                        entry: {
                            listingId,
                            isDraftListing
                        }
                    }
                },
                error
            )
        }
        setIsDeleting(false)
    }

    const relistListingAsync = async () => {
        try {
            await dispatch(relistListing(listingId)).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User relisted listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        tab: parsedTab
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to relist listing',
                    payload: {
                        kind: 'RelistListingError',
                        entry: {
                            listingId,
                            tab: parsedTab
                        }
                    }
                },
                error
            )
        }
    }

    const actions: ListingRowAction[] = useMemo(() => {
        switch (parsedTab) {
            case ListingTabKind.Enum.active:
                return [
                    { label: 'Edit', onAction: onEdit, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> },
                    { label: 'Delete', onAction: onDeleteInitiated, icon: <DeleteIcon height={24} width={24} /> }
                ]
            case ListingTabKind.Enum.draft:
                return [
                    { label: 'Edit', onAction: onEdit, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> },
                    { label: 'Delete', onAction: onDeleteInitiated, icon: <DeleteIcon height={24} width={24} /> }
                ]
            case ListingTabKind.Enum.sold:
                if (listingKind === 'active') {
                    return [
                        { label: 'Edit', onAction: onEdit, icon: <EditIcon height={24} width={24} /> },
                        { label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> },
                        { label: 'Delete', onAction: onDeleteInitiated, icon: <DeleteIcon height={24} width={24} /> }
                    ]
                } else {
                    return [{ label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> }]
                }

            case ListingTabKind.Enum.deleted:
                return [{ label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> }]
            case ListingTabKind.Enum.untracked:
                return [
                    { label: 'Edit', onAction: onEdit, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Quick relist', onAction: relistListingAsync, icon: <Refresh height={24} width={24} /> },
                    { label: 'Delete', onAction: onDeleteInitiated, icon: <DeleteIcon height={24} width={24} /> }
                ]
        }
    }, [parsedTab, listingKind])

    const doesListingKindStillMatchTab = useMemo(() => {
        switch (parsedTab) {
            case ListingTabKind.Enum.active:
                return listingKind === 'active'
            case ListingTabKind.Enum.draft:
                return listingKind === 'draft'
            case ListingTabKind.Enum.sold:
                return listingKind === 'active' || listingKind === 'completed'
            case ListingTabKind.Enum.deleted:
                return listingKind === 'completed'
            case ListingTabKind.Enum.untracked:
                return listingKind === 'active'
        }
    }, [listingKind, parsedTab])

    if (!listing) {
        return null
    }

    return (
        <>
            {doesListingKindStillMatchTab && !isDraftListingDeleting && (
                <BaseListingTableRow
                    listingId={listingId}
                    title={commonDetails?.title ?? ''}
                    price={commonDetails?.price ?? 0}
                    onClick={onDetail}
                    selectedMarkets={selectedMarkets}
                    actions={actions}
                    disabled={isListingDeleting || isDeleting}
                    isUpdating={isUpdating || isListingDeleting || isDeleting}
                    imageId={imageIds?.[0]}
                />
            )}

            {isDraftListing ? (
                <DeleteDraftListingConfirmationModal open={isDeleteModalOpen} onClose={onDeleteModalClose} />
            ) : (
                <DeleteActiveListingConfirmationModal open={isDeleteModalOpen} onClose={onDeleteModalClose} />
            )}
        </>
    )
}

const styles = {
    listingImageContainer: {
        height: '60px',
        width: '60px',
        marginRight: '12px'
    },
    listingImage: {
        borderRadius: '8px'
    },
    listingTitle: {
        lineHeight: '60px'
    },
    listingRow: {
        borderTop: '2px solid lightgrey'
    },
    marketImage: {
        height: '36px',
        width: '36px',
        marginRight: '8px',
        borderRadius: '8px',
        borderWidth: '1px',
        borderStroke: Colors.light.divider,
        BorderStyle: 'solid'
    }
}
